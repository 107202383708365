.uppy-Url {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
[data-uppy-theme=dark] .uppy-Url {
  background-color: #1f1f1f;
}

.uppy-Url-input {
  width: 90%;
  max-width: 650px;
  margin-bottom: 15px;
}
.uppy-size--md .uppy-Url-input {
  margin-bottom: 20px;
}

.uppy-Url-importButton {
  padding: 13px 25px;
}
.uppy-size--md .uppy-Url-importButton {
  padding: 13px 30px;
}